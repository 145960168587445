import fetch from "auth/FetchInterceptor";
import { AUTH_TOKEN } from "redux/constants/Auth";

const JwtAuthService = {};
const token = localStorage.getItem(AUTH_TOKEN);

JwtAuthService.login = function (data) {
  return fetch({
    url: "/usuario/login",
    method: "post",
    headers: {
      "public-request": "true",
      token: `Bearer ${token}`,
    },
    data: data,
  });
};

JwtAuthService.signUp = function (data) {
  return fetch({
    url: "/auth/signup",
    method: "post",
    headers: {
      "public-request": "true",
      token: `Bearer ${token}`,
    },
    data: data,
  });
};

export default JwtAuthService;
