import {
  InboxOutlined,
  TeamOutlined,
  MessageOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
  UserAddOutlined,
  TagOutlined,
  GroupOutlined,
  AppstoreOutlined,
  BarChartOutlined,
  ToolOutlined,
  RadarChartOutlined,
  SettingOutlined,
  FilterOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "apps",
    path: `${APP_PREFIX_PATH}/apps`,
    title: "sidenav.dashboard",
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "dashboard",
        path: `${APP_PREFIX_PATH}/indicadores/reporte`,
        role: ["ADMINISTRADOR"],
        title: "sidenav.dashboard",
        icon: BarChartOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "inbox",
        path: `${APP_PREFIX_PATH}/inbox`,
        title: "inbox",
        role: ["ADMINISTRADOR", "OPERADOR"],
        icon: InboxOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "chats",
        path: `${APP_PREFIX_PATH}/chats`,
        role: ["ADMINISTRADOR", "OPERADOR"],
        title: "chats",
        icon: MessageOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "sidenav.mail",
        path: `${APP_PREFIX_PATH}/mail/inbox`,
        role: ["ADMINISTRADOR", "OPERADOR"],
        title: "sidenav.mail",
        icon: MailOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "Etiquetas",
        path: ``,
        role: ["ADMINISTRADOR"],
        title: "sidenav.etiquetas",
        breadcrumb: false,
        icon: TagOutlined,
        submenu: [
          {
            key: "Lista de etiquetas",
            path: `${APP_PREFIX_PATH}/tag/list`,
            title: "sidenav.etiquetas.listar",
            icon: UnorderedListOutlined,
            submenu: [],
          },
          {
            key: "Crear etiquetas",
            path: `${APP_PREFIX_PATH}/tag/create`,
            title: "sidenav.etiquetas.crear",
            icon: TagOutlined,
            submenu: [],
          },
        ],
      },
      {
        key: "Grupos",
        path: ``,
        role: ["ADMINISTRADOR"],
        title: "sidenav.grupos",
        icon: GroupOutlined,
        submenu: [
          {
            key: "Listado de grupos",
            path: `${APP_PREFIX_PATH}/group/list`,
            title: "sidenav.grupos.listar",
            icon: UnorderedListOutlined,
            submenu: [],
          },
          {
            key: "Crear grupo",
            path: `${APP_PREFIX_PATH}/group/create`,
            title: "sidenav.grupos.crear",
            icon: UsergroupAddOutlined,
            submenu: [],
          },
        ],
      },
      {
        key: "operadores",
        path: "",
        role: ["ADMINISTRADOR"],
        title: "sidenav.operadores",
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "operadoresLista",
            path: `${APP_PREFIX_PATH}/operador/lista`,
            title: "sidenav.operadores.listar",
            icon: UnorderedListOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "operadoresAgregar",
            path: `${APP_PREFIX_PATH}/operador/agregar`,
            title: "sidenav.operadores.crear",
            icon: UserAddOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "sidenav.social-networks",
        path: ``,
        role: ["ADMINISTRADOR"],
        title: "sidenav.social-networks",
        breadcrumb: false,
        icon: RadarChartOutlined,
        submenu: [
          {
            key: "sidenav.social-networks.streams",
            path: `${APP_PREFIX_PATH}/data-stream/stream`,
            title: "sidenav.social-networks.streams",
            icon: SettingOutlined,
            submenu: [],
          },
          {
            key: "sidenav.social-networks.add-filters",
            path: `${APP_PREFIX_PATH}/data-stream/filter`,
            title: "sidenav.social-networks.add-filters",
            icon: FilterOutlined,
            submenu: [],
          },
          {
            key: "sidenav.social-networks.filters",
            path: `${APP_PREFIX_PATH}/data-stream/filter-list`,
            title: "sidenav.social-networks.filters",
            icon: ToolOutlined,
            submenu: [],
          },
          {
            key: "sidenav.social-networks.dashboard",
            path: `${APP_PREFIX_PATH}/data-stream/dashboard`,
            title: "sidenav.social-networks.dashboard",
            icon: BarChartOutlined,
            submenu: [],
          },
        ],
      },
      // {
      //   key: "sidenav.tiktok",
      //   path: ``,
      //   role: ["ADMINISTRADOR"],
      //   title: "sidenav.tiktok",
      //   breadcrumb: false,
      //   icon: RadarChartOutlined,
      //   submenu: [
      //     {
      //       key: "sidenav.tiktok-enabled",
      //       path: `${APP_PREFIX_PATH}/tiktok/enabled`,
      //       title: "sidenav.tiktok-enabled",
      //       icon: ToolOutlined,
      //       submenu: [],
      //     },
      //     {
      //       key: "sidenav.tiktok-settings",
      //       path: `${APP_PREFIX_PATH}/tiktok/settings`,
      //       title: "sidenav.tiktok-settings",
      //       icon: FilterOutlined,
      //       submenu: [],
      //     },
      //     {
      //       key: "sidenav.tiktok-status",
      //       path: `${APP_PREFIX_PATH}/tiktok/status`,
      //       title: "sidenav.tiktok-status",
      //       icon: SettingOutlined,
      //       submenu: [],
      //     },
      //   ],
      // },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
