import { 
    GET_STATUS, 
    GET_STATUS_FAILED, 
    GET_STATUS_SUCCESS,   
    CLEAN_SELECTED_FILTER,
    CREATE_FILTER,
    CREATE_FILTER_FAILED,
    CREATE_FILTER_SUCCESS,
    DELETE_FILTER,
    DELETE_FILTER_FAILED,
    DELETE_FILTER_SUCCESS,
    EDIT_FILTER,
    EDIT_FILTER_FAILED,
    EDIT_FILTER_SUCCESS,
    GET_FILTERS,
    GET_FILTERS_FAILED,
    GET_FILTERS_SUCCESS,
    SELECTED_FILTER,
    SET_BOUNDARIES,
    ADD_RULE_TYPE,
    ADD_RULE_VALUE,
 } from "redux/constants/DataStream";

 export const getFilters = (data) => {
  return {
    type: GET_FILTERS,
    payload: data,
  };
};

export const setRuleType = (data) => {
  return {
    type: ADD_RULE_TYPE,
    payload: data,
  };
};

export const setRuleValue = (data) => {
  return {
    type: ADD_RULE_VALUE,
    payload: data,
  };
};

export const getFiltersSuccess = (data) => {
  return {
    type: GET_FILTERS_SUCCESS,
    payload: data,
  };
};

export const getFiltersFailed = (msg) => {
  return {
    type: GET_FILTERS_FAILED,
    payload: msg,
  };
};


export const setBoundaries = (data) => {
    return {
      type: SET_BOUNDARIES,
      payload: data,
    };
  };  

export const createFilter = (data) => {
  return {
    type: CREATE_FILTER,
    payload: data,
  };
};

export const createFilterSuccess = (msg) => {
  return {
    type: CREATE_FILTER_SUCCESS,
    payload: msg,
  };
};
export const createFilterFailed = (msg) => {
  return {
    type: CREATE_FILTER_FAILED,
    payload: msg,
  };
};
export const editFilter = (data) => {
  return {
    type: EDIT_FILTER,
    payload: data,
  };
};

export const editFilterSuccess = (data) => {
  return {
    type: EDIT_FILTER_SUCCESS,
    payload: data,
  };
};
export const editFilterFailed = (msg) => {
  return {
    type: EDIT_FILTER_FAILED,
    payload: msg,
  };
};
  
export const deleteFilter = (data) => {
  return {
    type: DELETE_FILTER,
    payload: data,
  };
};

export const deleteFilterSuccess = (data) => {
  return {
    type: DELETE_FILTER_SUCCESS,
    payload: data,
  };
};
export const deleteFilterFailed = (msg) => {
  return {
    type: DELETE_FILTER_FAILED,
    payload: msg,
  };
};
  
export const filterSelected = (filter) => {
  return {
    type: SELECTED_FILTER,
    payload: filter,
  };
};
export const cleanFilterSelected = () => {
  return {
    type: CLEAN_SELECTED_FILTER,
    payload: null,
  };
};


export const getStatus = (socialNetwork) => {
  return {
    type: GET_STATUS,
    payload: socialNetwork,
  };
};
  
export const getStatusSuccess = (status) => {
    return {
      type: GET_STATUS_SUCCESS,
      payload: status,
    };
  };

export const getStatusFailed = (message) => {
  return {
    type: GET_STATUS_FAILED,
    payload: message,
  };
};

