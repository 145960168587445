import { all, fork, put, takeEvery } from "redux-saga/effects";
import { getStatusSuccess,
  getStatusFailed, 
  deleteFilterFailed, 
  deleteFilterSuccess, 
  getFilters, 
  getFiltersFailed, 
  getFiltersSuccess,
  createFilterFailed,
  createFilterSuccess,
} from "redux/actions/DataStream";
import { GET_STATUS, CREATE_FILTER, DELETE_FILTER, GET_FILTERS } from "redux/constants/DataStream";
import socialDataStream from "services/DataStreamService";
import { message } from "antd";


export function* createFilter() {
    yield takeEvery(CREATE_FILTER, function* (payload) {
      try {
        const response = yield socialDataStream.postCreateFiler(payload.payload);
        if (response.status >400) {
          throw response
        }
        message.success(response.data.mensaje); 
        yield put(createFilterSuccess(response.data.data));
      } catch (err) {
        message.error(JSON.stringify(err.message));
        yield put(createFilterFailed(err));
      }
    });
  }

  export function* getFiltersSaga() {
    //console.log("en saga getclients");
    yield takeEvery(GET_FILTERS, function* () {
      try {
        const response = yield socialDataStream.getFilters();
        if (response.status > 400) {
          throw response;
        }
        yield put(getFiltersSuccess(response.data.data));
      } catch (err) {
        message.error(JSON.stringify(err.message));
      yield put(getFiltersFailed(err));
    }
  });
}

export function* deleteFilters() {
  yield takeEvery(DELETE_FILTER, function* (payload) {
    try {
      const response = yield socialDataStream.deleteFilters(payload.payload);
      if (response.status > 400) {
        throw response;
      }
      message.success("Filtro eliminado correctamente");
      yield put(deleteFilterSuccess({}));
      yield put(getFilters());
    } catch (err) {
      // message.error(JSON.stringify(err.message));
      message.success("Error al eliminar filtro");
      yield put(deleteFilterFailed(err));
    }
  });
}


export function* getStatusDataStream() {
  yield takeEvery(GET_STATUS, function* (payload) {
    try {
      const response = yield socialDataStream.postGetStatus(payload.payload);
      if (response.status > 400) {
        throw response;
      }
      yield put(
        getStatusSuccess({
          taskName: response.data.taskName,
          running: response.data.running,
        })
      );
    } catch (err) {
      yield put(getStatusFailed(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getFiltersSaga),    
    fork(getFilters),
    fork(deleteFilters),
    fork(createFilter),
  ]);
}
