import {
  ASOCIATE_TAG_CLIENT,
  ASOCIATE_TAG_CLIENT_FAILED,
  ASOCIATE_TAG_CLIENT_SUCCESS,
  CLEAN_SELECTED_TAG,
  CREATE_TAG,
  CREATE_TAG_FAILED,
  CREATE_TAG_SUCCESS,
  DELETE_TAG,
  DELETE_TAG_FAILED,
  DELETE_TAG_SUCCESS,
  EDIT_TAG,
  EDIT_TAG_FAILED,
  EDIT_TAG_SUCCESS,
  GET_TAGS,
  GET_TAGS_FAILED,
  GET_TAGS_SUCCESS,
  SELECTED_TAG,
} from "redux/constants/Tag";

export const getTags = (data) => {
  return {
    type: GET_TAGS,
    payload: data,
  };
};

export const getTagsSuccess = (data) => {
  return {
    type: GET_TAGS_SUCCESS,
    payload: data,
  };
};

export const getTagsFailed = (msg) => {
  return {
    type: GET_TAGS_FAILED,
    payload: msg,
  };
};

export const createTag = (data) => {
  return {
    type: CREATE_TAG,
    payload: data,
  };
};

export const createTagSuccess = (data) => {
  return {
    type: CREATE_TAG_SUCCESS,
    payload: data,
  };
};
export const createTagFailed = (msg) => {
  return {
    type: CREATE_TAG_FAILED,
    payload: msg,
  };
};
export const editTag = (data) => {
  return {
    type: EDIT_TAG,
    payload: data,
  };
};

export const editTagSuccess = (data) => {
  return {
    type: EDIT_TAG_SUCCESS,
    payload: data,
  };
};
export const editTagFailed = (msg) => {
  return {
    type: EDIT_TAG_FAILED,
    payload: msg,
  };
};

export const deleteTag = (data) => {
  return {
    type: DELETE_TAG,
    payload: data,
  };
};

export const deleteTagSuccess = (data) => {
  return {
    type: DELETE_TAG_SUCCESS,
    payload: data,
  };
};
export const deleteTagFailed = (msg) => {
  return {
    type: DELETE_TAG_FAILED,
    payload: msg,
  };
};

export const tagSelected = (tag) => {
  return {
    type: SELECTED_TAG,
    payload: tag,
  };
};
export const cleanTagSelected = () => {
  return {
    type: CLEAN_SELECTED_TAG,
    payload: null,
  };
};

export const asociateTagClient = (data) => {
  return {
    type: ASOCIATE_TAG_CLIENT,
    payload: data,
  };
};

export const asociateTagClientSuccess = (data) => {
  return {
    type: ASOCIATE_TAG_CLIENT_SUCCESS,
    payload: data,
  };
};
export const asociateTagClientFailed = (msg) => {
  return {
    type: ASOCIATE_TAG_CLIENT_FAILED,
    payload: msg,
  };
};