import {
  CHECK_MESSAGES,
  CHECK_MESSAGES_FAILED,
  CHECK_MESSAGES_SUCCESS,
  CLEAN_DATA,
  FILTER_BY_TAG,
  FILTER_BY_TAG_FAILED,
  FILTER_BY_TAG_SUCCESS,
  GET_CLIENTS,
  GET_CLIENTS_FAILED,
  GET_CLIENTS_SUCCESS,
  GET_MESSAGES,
  GET_MESSAGES_FAILED,
  GET_MESSAGES_SUCCESS,
  RECEIVED_MESSAGE,
  SEND_MESSAGE,
  SEND_MESSAGE_FAILED,
  SEND_MESSAGE_SUCCESS,
  SET_CLIENT_SELECTED,
  SET_CLIENT_SELECTED_INBOX,
  EDIT_BOT_FEEDBACK,
  EDIT_BOT_FEEDBACK_SUCCESS,
  EDIT_BOT_FEEDBACK_FAILED,
  SET_CLIENT_SELECTED_MAIL_INBOX,
  GET_MAILS,
  GET_MAILS_FAILED,
  GET_MAILS_SUCCESS
} from "../constants/Chat";
export const getMessages = (body) => {
  return {
    type: GET_MESSAGES,
    payload: body, //en teoria tendria que ser el id del user
  };
};

export const getMessagesFailed = (err) => {
  return {
    type: GET_MESSAGES_FAILED,
    payload: err, //en teoria tendria que ser el id del user
  };
};

export const getMessagesSuccess = (data) => {
  return {
    type: GET_MESSAGES_SUCCESS,
    payload: data, //en teoria tendria que ser el id del user
  };
};

export const getMails = (body) => {
  return {
    type: GET_MAILS,
    payload: body, //en teoria tendria que ser el id del user
  };
};

export const getMailsFailed = (err) => {
  return {
    type: GET_MAILS_FAILED,
    payload: err, //en teoria tendria que ser el id del user
  };
};

export const getMailsSuccess = (data) => {
  return {
    type: GET_MAILS_SUCCESS,
    payload: data, //en teoria tendria que ser el id del user
  };
};

export const sendMessage = (body) => {
  return {
    type: SEND_MESSAGE,
    payload: body, //en teoria tendria que ser el id del user
  };
};

export const sendMessageFailed = (err) => {
  return {
    type: SEND_MESSAGE_FAILED,
    payload: err, //en teoria tendria que ser el id del user
  };
};

export const sendMessageSuccess = (data) => {
  return {
    type: SEND_MESSAGE_SUCCESS,
    payload: data, //en teoria tendria que ser el id del user
  };
};

export const setClientSelected = (client) => {
  return {
    type: SET_CLIENT_SELECTED,
    payload: client,
  };
};
export const setClientSelectedInbox = (client) => {
  return {
    type: SET_CLIENT_SELECTED_INBOX,
    payload: client,
  };
};
export const setClientSelectedMailInbox = (client) => {
  return {
    type: SET_CLIENT_SELECTED_MAIL_INBOX,
    payload: client,
  };
};
export const messageReceived = (data) => {
  return {
    type: RECEIVED_MESSAGE,
    payload: data,
  };
};

export const filterByTag = (tagsIds) => {
  return {
    type: FILTER_BY_TAG,
    payload: tagsIds,
  };
};

export const filterByTagSuccess = (data) => {
  return {
    type: FILTER_BY_TAG_SUCCESS,
    payload: data,
  };
};


export const filterByTagFailed = (message) => {
  return {
    type: FILTER_BY_TAG_FAILED,
    payload: message,
  };
};

export const getClients = (clients) => {
  return {
    type: GET_CLIENTS,
    payload: clients,
  };
};

export const getClientsSuccess = (clients) => {
  return {
    type: GET_CLIENTS_SUCCESS,
    payload: clients,
  };
};
export const getClientsFailed = (message) => {
  return {
    type: GET_CLIENTS_FAILED,
    payload: message,
  };
};

export const checkMessages = (data) => {
  return {
    type: CHECK_MESSAGES,
    payload: data,
  };
};
export const checkMessagesSuccess = (response) => {
  return {
    type: CHECK_MESSAGES_SUCCESS,
    payload: response,
  };
};
export const checkMessagesFailed = (message) => {
  return {
    type: CHECK_MESSAGES_FAILED,
    payload: message,
  };
};
export const cleanData = () => {
  return {
    type: CLEAN_DATA,
    payload: null,
  };
};
export const EditBotFeedback = (data) => {
  return {
    type: EDIT_BOT_FEEDBACK,
    payload: data,
  };
};
export const EditBotFeedbackFailed = (message) => {
  return {
    type: EDIT_BOT_FEEDBACK_FAILED,
    payload: message,
  };
};
export const EditBotFeedbackSuccess = (data) => {
  return {
    type: EDIT_BOT_FEEDBACK_SUCCESS,
    payload: data,
  };
};

