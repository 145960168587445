import {
  RouteGetClients,
  RoutePostCheckMessage,
  RoutePostGetMessage,
  RoutePostSendMessage,
  RouteEditBotFeedback
} from "constants/ChatConstant";
import { getHttp, postHttp } from "utils/apiHelper";

const chatService = {};

chatService.getGetClients = function () {
  return getHttp(RouteGetClients);
};

chatService.postGetMessage = function (data) {
  return postHttp(RoutePostGetMessage+"?cantidad=100", data);
};

chatService.postSendMessage = function (data) {
 
  return postHttp(RoutePostSendMessage, data);
};

chatService.postCheckMessages = function (data) {
  return postHttp(RoutePostCheckMessage, data);
};

chatService.postEditBotFeedback = function (data) {
  return postHttp(RouteEditBotFeedback, data);
};

export default chatService;
