import { message } from "antd";
import { all, fork, put, takeEvery } from "redux-saga/effects";
import {
  checkMessagesFailed,
  checkMessagesSuccess,
  getClientsSuccess,
  getMessagesFailed,
  getMessagesSuccess,
  sendMessageFailed,
  sendMessageSuccess,
  EditBotFeedbackSuccess,
  EditBotFeedbackFailed,
  getMailsSuccess,
  getMailsFailed,
} from "redux/actions/Chat";
import {
  CHECK_MESSAGES,
  GET_CLIENTS,
  GET_CLIENTS_FAILED,
  GET_MESSAGES,
  SEND_MESSAGE,
  EDIT_BOT_FEEDBACK,
  GET_MAILS,
} from "redux/constants/Chat";
import chatService from "services/ChatService";

export function* getMessages() {
  yield takeEvery(GET_MESSAGES, function* (payload) {
    try {
      const response = yield chatService.postGetMessage(payload.payload);
      if (response.status > 400) {
        throw response;
      }
      yield put(
        getMessagesSuccess({
          messages: response.data.data.results,
          idUsuarioPlataforma: payload.payload.idUsuarioPlataforma,
        })
      );
    } catch (err) {
      yield put(getMessagesFailed(err));
    }
  });
}
export function* getMails() {
  yield takeEvery(GET_MAILS, function* (payload) {
    try {
      const response = yield chatService.postGetMessage(payload.payload);
      if (response.status > 400) {
        throw response;
      }
      yield put(
        getMailsSuccess({
          messages: response.data.data.results,
          idUsuarioPlataforma: payload.payload.idUsuarioPlataforma,
        })
      );
    } catch (err) {
      yield put(getMailsFailed(err));
    }
  });
}
export function* getClients() {
  //console.log("en saga getclients");
  yield takeEvery(GET_CLIENTS, function* () {
    try {
      const response = yield chatService.getGetClients();
      // console.log(clients);
      if (response.status > 400) {
        throw response;
      }
      yield put(getClientsSuccess(response.data.data));
    } catch (err) {
      yield put({ type: GET_CLIENTS_FAILED, message: err.message });
    }
  });
}
export function* sendMessage() {
  yield takeEvery(SEND_MESSAGE, function* (payload) {
    try {
      const response = yield chatService.postSendMessage(payload.payload);
      if (response) {
        yield put(
          sendMessageSuccess({
            fecha: new Date(),
            visto: true,
            mensaje: payload.payload.mensajes[0].mensaje,
            idUsuarioPlataforma: payload.payload.idUsuarioPlataforma,
            idDataMensaje: payload.payload.idDataMensaje,
            emailFragments:payload.payload.emailFragments,
            origen: "bot",
          })
        );
      } else {
      }
    } catch (err) {
      yield put(sendMessageFailed(err));
    }
  });
}
export function* checkMessages() {
  yield takeEvery(CHECK_MESSAGES, function* (payload) {
    try {
      const response = yield chatService.postCheckMessages({
        idDataMensaje: payload.payload.idDataMensaje,
        idOperador: Number(payload.payload.idOperador),
      });
      if (response.status > 400) {
        throw response;
      }
      yield put(checkMessagesSuccess(response));
    } catch (err) {
      console.log("ERRRORRR");
      yield put(checkMessagesFailed(err));
    }
  });
}
export function* editBotFeedback() {
  yield takeEvery(EDIT_BOT_FEEDBACK, function* (payload) {
    try {
      const response = yield chatService.postEditBotFeedback({
        idMensaje: payload.payload.idMensaje,
        sentimiento: payload.payload.sentimiento,
      });
      if (response.status > 400) {
        throw response;
      }
      yield put(EditBotFeedbackSuccess(payload.payload));
      message.success(response.data.mensaje);
    } catch (err) {
      yield put(EditBotFeedbackFailed(err.message));
      message.error(JSON.stringify(err.message));
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(getMessages),
    fork(sendMessage),
    fork(getMails),
    fork(getClients),
    fork(checkMessages),
    fork(editBotFeedback),
  ]);
}
