import { RouteGetOperators } from "constants/OperatorsConstant";
import { message } from "antd";
import { all, fork, put, takeEvery } from "redux-saga/effects";
import {
  createOperator, 
  deleteOperatorFailed, 
  deleteOperatorSuccess, 
  editOperatorFailed, 
  editOperatorSuccess, 
  getOperatorsFailed, 
  getOperatorsSuccess,
  getOperators 
} from "redux/actions/Operator";
import { 
  CREATE_OPERATOR, 
  DELETE_OPERATOR, 
  EDIT_OPERATOR, 
  GET_OPERATORS 
} from "redux/constants/Operator";
import operatorsService from "services/OperatorsService";

export function* getOperator() {
  yield takeEvery(GET_OPERATORS, function* () {
    try {
      const response = yield operatorsService.getOperators();
      if (response.status >400) {
        throw response
      }
      // message.success(response.data.mensaje); 
      yield put(getOperatorsSuccess(response.data.data));
    } catch (err) {
      message.error(JSON.stringify(err.message));
      yield put(getOperatorsFailed(err));
    }
  });
}

export function* createOperators() {
  yield takeEvery(CREATE_OPERATOR, function* (payload) {
    try {
      const response = yield operatorsService.createOperator(payload.payload);
      if (response.status >400) {
        throw response
      }
      message.success(response.data.mensaje); 
      yield put(getOperatorsSuccess(response.data.data));
    } catch (err) {
      message.error(JSON.stringify(err.message));
      yield put(getOperatorsFailed(err));
    }
  });
}

export function* updateOperator() {
  yield takeEvery(EDIT_OPERATOR, function* (payload) {
    try {
      const response = yield operatorsService.updateOperator(payload.payload);
      if (response.status > 400) {
        throw response;
      }
      message.success(response.data.mensaje);
      yield put(editOperatorSuccess({}));
      yield put(getOperators());
    } catch (err) {
      message.error(JSON.stringify(err.message));
      yield put(editOperatorFailed(err));
    }
  });
}
export function* deleteOperator() {
  yield takeEvery(DELETE_OPERATOR, function* (payload) {
    try {
      const response = yield operatorsService.deleteOperator(payload.payload);
      if (response.status > 400) {
        throw response;
      }
      message.success(response.data.mensaje);
      yield put(deleteOperatorSuccess({}));
      yield put(getOperators());
    } catch (err) {
      message.error(JSON.stringify(err.message));
      yield put(deleteOperatorFailed(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getOperator),
    fork(createOperators),
    fork(deleteOperator),
    fork(updateOperator),
  ]);
}
