import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        {/* 
        <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} /> */}
        <Route path={`${APP_PREFIX_PATH}/inbox`} component={lazy(() => import(`./home`))} />
        <Route path={`${APP_PREFIX_PATH}/chats`} component={lazy(() => import(`./chats`))} />
        <Route path={`${APP_PREFIX_PATH}/operator`} component={lazy(() => import(`./operator`))} />
        <Route path={`${APP_PREFIX_PATH}/tag/list`} component={lazy(() => import(`./tag/list`))} />
        <Route path={`${APP_PREFIX_PATH}/tag/create`} component={lazy(() => import(`./tag/create`))} />
        <Route path={`${APP_PREFIX_PATH}/group/list`} component={lazy(() => import(`./group/list`))} />
        <Route path={`${APP_PREFIX_PATH}/group/create`} component={lazy(() => import(`./group/create`))} />
        <Route path={`${APP_PREFIX_PATH}/operador/lista`} component={lazy(() => import(`./operator/index`))} />
        <Route path={`${APP_PREFIX_PATH}/mail/inbox`} component={lazy(() => import(`./mail/list`))} />
        <Route path={`${APP_PREFIX_PATH}/mail/ver`} component={lazy(() => import(`./mail/detail`))} />
        <Route path={`${APP_PREFIX_PATH}/operador/agregar`} component={lazy(() => import(`./operator/new-operator`))} />
        <Route path={`${APP_PREFIX_PATH}/indicadores/reporte`} component={lazy(() => import(`./indicator/index`))} />
        <Route path={`${APP_PREFIX_PATH}/data-stream/stream`} component={lazy(() => import(`./data-stream/stream`))} />
        <Route path={`${APP_PREFIX_PATH}/data-stream/dashboard`} component={lazy(() => import(`./data-stream/dashboard`))} />
        <Route path={`${APP_PREFIX_PATH}/data-stream/filter`} component={lazy(() => import(`./data-stream/filter`))} />
        <Route path={`${APP_PREFIX_PATH}/data-stream/filter-list`} component={lazy(() => import(`./data-stream/filter-list`))} />
        <Route path={`${APP_PREFIX_PATH}/tiktok/enabled`} component={lazy(() => import(`./tiktok/enabled`))} />
        <Route path={`${APP_PREFIX_PATH}/tiktok/settings`} component={lazy(() => import(`./tiktok/settings`))} />
        <Route path={`${APP_PREFIX_PATH}/tiktok/status`} component={lazy(() => import(`./tiktok/status`))} />
        {/* <Route path={`${APP_PREFIX_PATH}/eliminados`} component={lazy(() => import(`./home/deletedMessages/DeletedMessages`))} /> */}
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/inbox`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);