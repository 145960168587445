const {
  GET_OPERATORS,
  GET_OPERATORS_SUCCESS,
  GET_OPERATORS_FAILED,
  CREATE_OPERATOR,
  CREATE_OPERATOR_SUCCESS,
  CREATE_OPERATOR_FAILED,
  SELECTED_OPERATOR,
  CLEAN_SELECTED_OPERATOR,
  EDIT_OPERATOR,
  EDIT_OPERATOR_SUCCESS,
  EDIT_OPERATOR_FAILED,
  DELETE_OPERATOR,
  DELETE_OPERATOR_SUCCESS,
  DELETE_OPERATOR_FAILED
} = require("redux/constants/Operator");

const initState = {
  operators: [],
  loadingOperators: false,
  errorMessage: {},
  operatorSelected: {}
};

const operator = (state = initState, action) => {
  switch (action.type) {
    case GET_OPERATORS:
      return {
        ...state,
        loadingOperators: true,
      };
    case GET_OPERATORS_SUCCESS:
      return {
        ...state,
        loadingOperators: false,
        operators: action.payload,
      };
    case GET_OPERATORS_FAILED:
      return {
        ...state,
        loadingOperators: false,
        errorMessage: action.payload
      };
    case CREATE_OPERATOR:
      return {
        ...state,
        loadingOperators: true,
      }
    case CREATE_OPERATOR_SUCCESS:
      return {
        ...state,
        loadingOperators: true,
      }
    case CREATE_OPERATOR_FAILED:
      return {
        ...state,
        loadingOperators: true,
      }
    case SELECTED_OPERATOR:
      return {
        ...state,
        operatorSelected: action.payload,
      };
    case CLEAN_SELECTED_OPERATOR:
      return {
        ...state,
        operatorSelected: {},
      };
    case EDIT_OPERATOR:
      return {
        ...state,
        loadingTag: true
      };
    case EDIT_OPERATOR_SUCCESS:
      return {
        ...state,
        loadingTag: false
      };
    case EDIT_OPERATOR_FAILED:
      return {
        ...state,
        loadingTag: false
      };
    case DELETE_OPERATOR:
      return { 
        ...state, 
        loadingOperators: true 
      };
    case DELETE_OPERATOR_SUCCESS:
      return { 
        ...state, 
        loadingOperators: false 
      };
    case DELETE_OPERATOR_FAILED:
      return { 
        ...state, 
        loadingOperators: false 
      };

    default:
      return { ...state };
  }
};

export default operator;