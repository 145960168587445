import {
  RouteDeleteGroups,
  RouteEditGroups,
  RouteGetGroups,
  RoutePostGroups
} from "constants/GroupConstant";
import { deleteHttp, getHttp, postHttp, updateHttp } from "utils/apiHelper";

const groupService = {};

groupService.getGroups = function () {
  return getHttp(RouteGetGroups);
};

groupService.postGroups = function (data) {
  return postHttp(RoutePostGroups, data);
};

groupService.updateGroups = function (data) {
  return updateHttp(RouteEditGroups + "/" + data.id, {
    nombre: data.nombre,
    descripcion: data.descripcion,
    tags: data.tags,
  });
};

groupService.deleteGroups = function (data) {
  return deleteHttp(RouteDeleteGroups+ "/" + data.id, data);
};

export default groupService;
