const {
  GET_TAGS,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAILED,
  CREATE_TAG,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_FAILED,
  EDIT_TAG,
  EDIT_TAG_SUCCESS,
  EDIT_TAG_FAILED,
  SELECTED_TAG,
  CLEAN_SELECTED_TAG,
  DELETE_TAG,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAILED,
  ASOCIATE_TAG_CLIENT,
  ASOCIATE_TAG_CLIENT_SUCCESS,
  ASOCIATE_TAG_CLIENT_FAILED,
} = require("redux/constants/Tag");

const initState = {
  loadingTag: false,
  tags: [],
  tagSelected: {},
};
const tag = (state = initState, action) => {
  switch (action.type) {
    case GET_TAGS:
      return { ...state, loadingTag: true };
    case GET_TAGS_SUCCESS:
      return { ...state, loadingTag: false, tags: action.payload };
    case GET_TAGS_FAILED:
      return { ...state, loadingTag: false };

    case CREATE_TAG:
      return { ...state, loadingTag: true };
    case CREATE_TAG_SUCCESS:
      return { ...state, loadingTag: false };
    case CREATE_TAG_FAILED:
      return { ...state, loadingTag: false };

    case SELECTED_TAG:
      return {
        ...state,
        tagSelected: action.payload,
      };
    case CLEAN_SELECTED_TAG:
      return {
        ...state,
        tagSelected: {},
      };

    case EDIT_TAG:
      return { ...state, loadingTag: true };
    case EDIT_TAG_SUCCESS:
      return { ...state, loadingTag: false };
    case EDIT_TAG_FAILED:
      return { ...state, loadingTag: false };

    case ASOCIATE_TAG_CLIENT:
      return { ...state, loadingTag: true };
    case ASOCIATE_TAG_CLIENT_SUCCESS:
      return { ...state, loadingTag: false };
    case ASOCIATE_TAG_CLIENT_FAILED:
      return { ...state, loadingTag: false };

    case DELETE_TAG:
      return { ...state, loadingTag: true };
    case DELETE_TAG_SUCCESS:
      return { ...state, loadingTag: false };
    case DELETE_TAG_FAILED:
      return { ...state, loadingTag: false };

    default:
      return {
        ...state,
      };
  }
};

export default tag;
