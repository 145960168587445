export const SET_BOUNDARIES = 'SET_BOUNDARIES';
export const GET_FILTERS = "GET_FILTERS";
export const GET_FILTERS_SUCCESS = "GET_FILTERS_SUCCESS";
export const GET_FILTERS_FAILED = "GET_FILTERS_FAILED";

export const CREATE_FILTER = "CREATE_FILTER";
export const CREATE_FILTER_SUCCESS = "CREATE_FILTER_SUCCESS";
export const CREATE_FILTER_FAILED = "CREATE_FILTER_FAILED";

export const EDIT_FILTER = "EDIT_FILTER";
export const EDIT_FILTER_SUCCESS = "EDIT_FILTER_SUCCESS";
export const EDIT_FILTER_FAILED = "EDIT_FILTER_FAILED";

export const DELETE_FILTER = "DELETE_FILTER";
export const DELETE_FILTER_SUCCESS = "DELETE_FILTER_SUCCESS";
export const DELETE_FILTER_FAILED = "DELETE_FILTER_FAILED";

export const SELECTED_FILTER = "SELECTED_FILTER";
export const CLEAN_SELECTED_FILTER = "CLEAN_SELECTED_FILTER";

export const GET_STATUS = 'GET_STATUS'
export const GET_STATUS_FAILED = 'GET_STATUS_FAILED'
export const GET_STATUS_SUCCESS = 'GET_STATUS_SUCCESS'

export const ADD_RULE_VALUE = 'ADD_RULE_VALUE'
export const ADD_RULE_TYPE = 'ADD_RULE_TYPE'
