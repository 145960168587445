const {
  GET_GROUPS,
  GET_GROUPS_SUCCESS,
  GET_GROUPS_FAILED,
  CREATE_GROUP,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_FAILED,
  EDIT_GROUP,
  EDIT_GROUP_SUCCESS,
  EDIT_GROUP_FAILED,
  SELECTED_GROUP,
  CLEAN_SELECTED_GROUP,
  DELETE_GROUP,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAILED,
} = require("redux/constants/Group");

const initState = {
  loadingGroup: false,
  groups: [],
  groupSelected: {},
};
const group = (state = initState, action) => {
  switch (action.type) {
    case GET_GROUPS:
      return { ...state, loadingGroup: true };
    case GET_GROUPS_SUCCESS:
      return { ...state, loadingGroup: false, groups: action.payload };
    case GET_GROUPS_FAILED:
      return { ...state, loadingGroup: false };

    case CREATE_GROUP:
      return { ...state, loadingGroup: true };
    case CREATE_GROUP_SUCCESS:
      return { ...state, loadingGroup: false };
    case CREATE_GROUP_FAILED:
      return { ...state, loadingGroup: false };

    case SELECTED_GROUP:
      return {
        ...state,
        groupSelected: action.payload,
      };
    case CLEAN_SELECTED_GROUP:
      return {
        ...state,
        groupSelected: {},
      };

    case EDIT_GROUP:
      return { ...state, loadingGroup: true };
    case EDIT_GROUP_SUCCESS:
      return { ...state, loadingGroup: false };
    case EDIT_GROUP_FAILED:
      return { ...state, loadingGroup: false };

    case DELETE_GROUP:
      return { ...state, loadingGroup: true };
    case DELETE_GROUP_SUCCESS:
      return { ...state, loadingGroup: false };
    case DELETE_GROUP_FAILED:
      return { ...state, loadingGroup: false };
    default:
      return {
        ...state,
      };
  }
};

export default group;
