import {
  RoutePostCreateFiler,
  RoutePostObtainStatus,
  RoutePostStartTask,
  RoutePostStopTask,
  RouteDeleteFilters,
  RouteGetFilters,
} from "constants/DataStreamConstant";
import { deleteHttp, getHttp, postHttp } from "utils/apiHelper";

const dataStreamsService = {};

dataStreamsService.postGetStatus = function (data) {
  return postHttp(RoutePostObtainStatus + "/" + data);
};

dataStreamsService.getFilters = function () {
  return getHttp(RouteGetFilters);
};

dataStreamsService.deleteFilters = function (data) {
  return deleteHttp(RouteDeleteFilters + "/" + data.id);
};

dataStreamsService.postCreateFiler = function (data) {
  return postHttp(RoutePostCreateFiler, data);
};

dataStreamsService.postStartTask = function (data) {
  return postHttp(RoutePostStartTask + "/" + data);
};

dataStreamsService.postStopTask = function (data) {
  return postHttp(RoutePostStopTask + "/" + data);
};

dataStreamsService.postGetStatus = function (data) {
  return postHttp(RoutePostObtainStatus + "/" + data);
};

export default dataStreamsService;
