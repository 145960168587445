export const SIGNIN = 'SIGNIN'; 
export const AUTHENTICATED = 'AUTHENTICATED';
export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SHOW_AUTH_MESSAGE = 'SHOW_AUTH_MESSAGE'
export const HIDE_AUTH_MESSAGE = 'HIDE_AUTH_MESSAGE'
export const SHOW_LOADING = 'SHOW_LOADING'
export const HIDE_LOADING = 'HIDE_LOADING'
export const AUTH_TOKEN = 'auth_token'

export const AUTH_ID = 'auth_id'
export const AUTH_NOMBRE = 'auth_nombre'
export const AUTH_APELLIDO = 'auth_apellido'
export const AUTH_EMAIL = 'auth_email'
export const AUTH_ROL = 'auth_rol'
export const AUTH_TIPO_DOCUMENTO = 'auth_tipo_documento'
export const AUTH_NRO_DOCUMENTO = 'auth_nro_documento'
export const AUTH_ID_EMPRESA = 'auth_id_empresa'