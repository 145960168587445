import { message } from "antd";
import { all, fork, put, takeEvery } from "redux-saga/effects";
import {
  createGroupFailed,
  createGroupSuccess,
  deleteGroupFailed,
  deleteGroupSuccess,
  editGroupFailed,
  editGroupSuccess,
  getGroups,
  getGroupsFailed,
  getGroupsSuccess,
} from "redux/actions/Group";
import {
  CREATE_GROUP,
  DELETE_GROUP,
  EDIT_GROUP,
  GET_GROUPS,
} from "redux/constants/Group";
import groupService from "services/GroupService";

export function* getGroupss() {
  //console.log("en saga getclients");
  yield takeEvery(GET_GROUPS, function* () {
    try {
      const response = yield groupService.getGroups();
      // console.log(clients);
      if (response.status > 400) {
        throw response;
      }
      // message.success(response.data.mensaje);
      yield put(getGroupsSuccess(response.data.data));
    } catch (err) {
      message.error(JSON.stringify(err.message));
      yield put(getGroupsFailed(err));
    }
  });
}
export function* createGroup() {
  yield takeEvery(CREATE_GROUP, function* (payload) {
    try {
      const response = yield groupService.postGroups(payload.payload);
      if (response.status > 400) {
        throw response;
      }
      message.success(response.data.mensaje);
      yield put(createGroupSuccess({}));
    } catch (err) {
      message.error(JSON.stringify(err.message));
      yield put(createGroupFailed(err));
    }
  });
}
export function* updateGroup() {
  yield takeEvery(EDIT_GROUP, function* (payload) {
    try {
      const response = yield groupService.updateGroups(payload.payload);
      if (response.status > 400) {
        throw response;
      }
      message.success(response.data.mensaje);
      yield put(editGroupSuccess({}));
      yield put(getGroups());
    } catch (err) {
      message.error(JSON.stringify(err.message));
      yield put(editGroupFailed(err));
    }
  });
}
export function* deleteGroup() {
  yield takeEvery(DELETE_GROUP, function* (payload) {
    try {
      const response = yield groupService.deleteGroups(payload.payload);
      if (response.status > 400) {
        throw response;
      }
      message.success(response.data.mensaje);
      yield put(deleteGroupSuccess({}));
      yield put(getGroups());
    } catch (err) {
      message.error(JSON.stringify(err.message));
      yield put(deleteGroupFailed(err));
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(getGroupss),
    fork(createGroup),
    fork(deleteGroup),
    fork(updateGroup),
  ]);
}
