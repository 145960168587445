const dev = {
  API_DATA_STREAM_TIKTOK_URL: process.env.REACT_APP_API_DATA_STREAM_TIKTOK || "http://localhost:3009",
  API_COMMUNICATIONS_URL:
    process.env.REACT_APP_API_COMMUNICATIONS || "http://localhost:3001",
  API_USERS_URL: process.env.REACT_APP_API_USERS || "http://localhost:3002",
  ANTIGUEDAD_CORTE: process.env.REACT_APP_ANTIGUEDAD_CORTE || 24,
  API_DATA_STREAM_URL: process.env.REACT_APP_API_DATA_STREAM || "http://localhost:3005",
  PUBLIC_URL: process.env.REACT_APP_PUBLIC_URL || "http://localhost:3000",
  WS_URL: process.env.REACT_APP_WS_URL || "ws://localhost:81",
};

const prod = {
  API_DATA_STREAM_TIKTOK_URL: process.env.REACT_APP_API_DATA_STREAM_TIKTOK,
  API_COMMUNICATIONS_URL: process.env.REACT_APP_API_COMMUNICATIONS,
  ANTIGUEDAD_CORTE: process.env.REACT_APP_ANTIGUEDAD_CORTE,

  PUBLIC_URL: process.env.REACT_APP_PUBLIC_URL,
  API_USERS_URL: process.env.REACT_APP_API_USERS,
  API_DATA_STREAM_URL: process.env.REACT_APP_API_DATA_STREAM,
  WS_URL: process.env.REACT_APP_WS_URL,
};

const test = {
  API_DATA_STREAM_TIKTOK_URL: process.env.REACT_APP_API_DATA_STREAM_TIKTOK,
  API_COMMUNICATIONS_URL: process.env.REACT_APP_API_COMMUNICATIONS,
  PUBLIC_URL: process.env.REACT_APP_PUBLIC_URL,
  ANTIGUEDAD_CORTE: process.env.REACT_APP_ANTIGUEDAD_CORTE,

  API_USERS_URL: process.env.REACT_APP_API_USERS,
  API_DATA_STREAM_URL: process.env.REACT_APP_API_DATA_STREAM,
  WS_URL: process.env.REACT_APP_WS_URL,
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      break;
  }
};

export const env = getEnv();
