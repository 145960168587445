import { combineReducers } from "redux";
import Auth from "./Auth";
import Chats from "./Chat";
import Theme from "./Theme";
import Operator from "./Operator";
import Tag from "./Tag";
import Group from "./Group";
import DataStream from "./DataStream";

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  chat: Chats,
  operator: Operator,
  tag: Tag,
  group: Group,
  dataStream: DataStream
});

export default reducers;
