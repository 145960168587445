import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";

export const APP_NAME = "OMNI by Preteco";

export const API_COMMUNICATIONS_URL = env.API_COMMUNICATIONS_URL;
export const API_DATA_STREAM_URL = env.API_DATA_STREAM_URL;
export const API_DATA_STREAM_TIKTOK = env.API_DATA_STREAM_TIKTOK_URL;
export const API_USERS_URL = env.API_USERS_URL;
export const API_WS_URL = env.WS_URL;

export const PUBLIC_URL = env.PUBLIC_URL;
export const ANTIGUEDAD_CORTE = env.ANTIGUEDAD_CORTE;
export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "es",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: "light",
  direction: DIR_LTR,
};
