import {
    CLEAN_SELECTED_GROUP,
    CREATE_GROUP,
    CREATE_GROUP_FAILED,
    CREATE_GROUP_SUCCESS,
    DELETE_GROUP,
    DELETE_GROUP_FAILED,
    DELETE_GROUP_SUCCESS,
    EDIT_GROUP,
    EDIT_GROUP_FAILED,
    EDIT_GROUP_SUCCESS,
    GET_GROUPS,
    GET_GROUPS_FAILED,
    GET_GROUPS_SUCCESS,
    SELECTED_GROUP,
  } from "redux/constants/Group";
  
  export const getGroups = (data) => {
    return {
      type: GET_GROUPS,
      payload: data,
    };
  };
  
  export const getGroupsSuccess = (data) => {
    return {
      type: GET_GROUPS_SUCCESS,
      payload: data,
    };
  };
  
  export const getGroupsFailed = (msg) => {
    return {
      type: GET_GROUPS_FAILED,
      payload: msg,
    };
  };
  
  export const createGroup = (data) => {
    return {
      type: CREATE_GROUP,
      payload: data,
    };
  };
  
  export const createGroupSuccess = (data) => {
    return {
      type: CREATE_GROUP_SUCCESS,
      payload: data,
    };
  };
  export const createGroupFailed = (msg) => {
    return {
      type: CREATE_GROUP_FAILED,
      payload: msg,
    };
  };
  export const editGroup = (data) => {
    return {
      type: EDIT_GROUP,
      payload: data,
    };
  };
  
  export const editGroupSuccess = (data) => {
    return {
      type: EDIT_GROUP_SUCCESS,
      payload: data,
    };
  };
  export const editGroupFailed = (msg) => {
    return {
      type: EDIT_GROUP_FAILED,
      payload: msg,
    };
  };
  
  export const deleteGroup = (data) => {
    return {
      type: DELETE_GROUP,
      payload: data,
    };
  };
  
  export const deleteGroupSuccess = (data) => {
    return {
      type: DELETE_GROUP_SUCCESS,
      payload: data,
    };
  };
  export const deleteGroupFailed = (msg) => {
    return {
      type: DELETE_GROUP_FAILED,
      payload: msg,
    };
  };
  
  export const groupSelected = (group) => {
    return {
      type: SELECTED_GROUP,
      payload: group,
    };
  };
  export const cleanGroupSelected = () => {
    return {
      type: CLEAN_SELECTED_GROUP,
      payload: null,
    };
  };
  