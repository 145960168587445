export const GET_OPERATORS = "GET_OPERATORS";
export const GET_OPERATORS_FAILED = "GET_OPERATORS_FAILED";
export const GET_OPERATORS_SUCCESS = "GET_OPERATORS_SUCCESS";

export const CREATE_OPERATOR = "CREATE_OPERATOR";
export const CREATE_OPERATOR_SUCCESS = "CREATE_OPERATOR_SUCCESS";
export const CREATE_OPERATOR_FAILED = "CREATE_OPERATOR_FAILED";

export const SELECTED_OPERATOR = "SELECTED_OPERATOR";
export const CLEAN_SELECTED_OPERATOR = "CLEAN_SELECTED_OPERATOR";

export const EDIT_OPERATOR = "EDIT_OPERATOR";
export const EDIT_OPERATOR_SUCCESS = "EDIT_OPERATOR_SUCCESS";
export const EDIT_OPERATOR_FAILED = "EDIT_OPERATOR_FAILED";

export const DELETE_OPERATOR = "DELETE_OPERATOR";
export const DELETE_OPERATOR_SUCCESS = "DELETE_OPERATOR_SUCCESS";
export const DELETE_OPERATOR_FAILED = "DELETE_OPERATOR_FAILED";
