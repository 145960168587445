import React from "react";
import { LogoutOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Menu } from "antd";
import Icon from "components/util-components/Icon";
import { connect, useSelector } from "react-redux";
import { signOut } from "redux/actions/Auth";

const menuItem = [
  // {
  // 	title: "Edit Profile",
  // 	icon: EditOutlined ,
  // 	path: "/"
  //   },
  //   {
  // 	title: "Account Setting",
  // 	icon: SettingOutlined,
  // 	path: "/"
  //   },
  //   {
  // 	title: "Billing",
  // 	icon: ShopOutlined ,
  // 	path: "/"
  // },
  //   {
  // 	title: "Help Center",
  // 	icon: QuestionCircleOutlined,
  // 	path: "/"
  // }
];

export const NavProfile = ({ signOut }) => {
  const stateAuth = useSelector((state) => state.auth);
  const { user } = stateAuth;
  //const { closeSocket } = useSocketChat();

  const profileImg = "/img/avatars/thumb-1.jpg";
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar
            style={{
              color: "#2167ff",
              backgroundColor: "#ecf1fe",
            }}
          >
            {user?.nombre?.charAt(0).toUpperCase()}
          </Avatar>
          <div className="pl-3">
            <h4 className="mb-0">{user?.nombre + " " + user?.apellido}</h4>
            <span className="text-muted">{user?.rol}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.length + 1} onClick={(e) => logOutSession()}>
            <span>
              <LogoutOutlined />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );

  const logOutSession = () => {
    //closeSocket();
    signOut();
  };
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar
            style={{
              color: "#2167ff",
              backgroundColor: "#ecf1fe",
            }}
          >
            {user?.nombre?.charAt(0).toUpperCase()}
          </Avatar>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default connect(null, { signOut })(NavProfile);
