import { Typography } from "antd";
import React from "react";

const index = () => {
  const { Title } = Typography;
  return (
    <div style={{ paddingBlock: "2rem", paddingInline: "2rem" }}>
      <Title level={2}>Politicas de Privacidad</Title>
      <p>
        <Title level={3}>
          LINEAMIENTOS PARA EL TRATAMIENTO DE DATOS OBTENIDOS MEDIANTE APIs A REDES
          SOCIALES U OTRAS FUENTES PÚBLICAS DE INFORMACIÓN
        </Title>
        <Title level={4}>
          I. OBJETIVO <br />
          II. ALCANCE <br /> III. CONTENIDO <br /> IV. INCUMPLIMIENTO DE LA POLITICA
          <br />
          V. LINEA ETICA <br /> VI. VIGENCIA
        </Title>
        <p>
            I. OBJETIVO Establecer de manera clara las pautas y los procedimientos que
          deben cumplir todos los miembros de PRETECO y/o sus empresas relacionadas que,
          con motivo de sus funciones en el desarrollo de sistemas, aplicaciones,
          (COMPLETEN), deban obtener, recopilar, analizar y/o de cualquier otra forma
          realizar tratamientos de datos obtenidos mediante APIs a redes sociales u otras
          fuentes públicas de información.
        </p>{" "}
        <p>
          {" "}
          II. ALCANCE Esta política es de cumplimiento obligatorio para todos los miembros
          (empleados, socios y demás colaboradores sin excepción, incluyendo proveedores,
          asesores y sub contratistas) que se encuentren cumpliendo tareas relacionadas al
          desarrollo de sistemas, aplicaciones, (COMPLETEN), y deban obtener, recopilar,
          analizar y/o de cualquier otra forma realizar tratamientos de datos obtenidos
          mediante APIs a redes sociales u otras fuentes públicas de información, siendo
          responsables de hacerla cumplir, sin importar el lugar físico donde se
          encuentren.{" "}
        </p>{" "}
        <p>
          III. CONTENIDO A) DEFINICIONES/CONCEPTOS • Red social: Servicio de la sociedad
          de la información que ofrece a los usuarios una plataforma de comunicación a
          través de internet para que estos generen un perfil con sus datos, facilitando
          la creación de comunidades con base en criterios comunes y permitiendo la
          comunicación de sus usuarios, de modo que pueden interactuar mediante mensajes,
          compartir información, imágenes o vídeos, permitiendo que estas publicaciones
          sean accesibles de forma inmediata por todos los usuarios de su grupo. • Fuente
          pública: Aquellas bases de datos cuya consulta puede ser realizada por cualquier
          persona, no impedida por una norma limitativa o sin más requisito que, en su
          caso, el pago de una contraprestación. • Datos personales: Información de
          cualquier tipo referida a personas físicas o de existencia ideal determinadas o
          determinables. • Datos sensibles: Datos personales que revelan origen racial y
          étnico, opiniones políticas, convicciones religiosas, filosóficas o morales,
          afiliación sindical e información referente a la salud o a la vida sexual. •
          Archivo, registro, base o banco de datos: Indistintamente, designan al conjunto
          organizado de datos personales que sean objeto de tratamiento o procesamiento,
          electrónico o no, cualquiera que fuere la modalidad de su formación,
          almacenamiento, organización o acceso. • Tratamiento de datos: Operaciones y
          procedimientos sistemáticos, electrónicos o no, que permitan la recolección,
          conservación, ordenación, almacenamiento, modificación, relacionamiento,
          evaluación, bloqueo, destrucción, y en general el procesamiento de datos
          personales, así como también su cesión a terceros a través de comunicaciones,
          consultas, interconexiones o transferencias. • Titular de los datos: Toda
          persona física o persona de existencia ideal con domicilio legal o delegaciones
          o sucursales en el país, cuyos datos sean objeto del tratamiento al que se
          refiere la presente ley. • Usuario de datos: Toda persona, pública o privada que
          realice a su arbitrio el tratamiento de datos, ya sea en archivos, registros o
          bancos de datos propios o a través de conexión con los mismos. • Disociación de
          datos: Todo tratamiento de datos personales de manera que la información
          obtenida no pueda asociarse a persona determinada o determinable. [VEAN SI
          ALGUNO FALTA O SI CREEN QUE ESTÁ DE MÁS] B) LINEAMIENTOS APLICABLES AL
          TRATAMIENTO DE DATOS OBTENIDOS MEDIANTE APIs A REDES SOCIALES U OTRAS FUENTES
          PÚBLICAS DE INFORMACIÓN. Los desarrollos de (XXXX COMPLETEN) realizados por
          Preteco que de cualquier modo intercambien o compartan información y /o
          funcionalidades con otros desarrollos, programas, SW o aplicaciones deberán
          respetar en todo momento los siguientes lineamientos, las políticas de seguridad
          de la información y la normativa aplicable. Datos que pueden obtenerse o
          recopilarse: • Los datos objeto de tratamiento deben ser adecuados, pertinentes
          y no excesivos en relación a la finalidad que motiva su obtención o recolección.
          • Los datos personales que se encuentren disponibles en las redes sociales u
          otras fuentes públicas de información podrán recopilarse u obtenerse siempre y
          cuando medie un proceso previo de disociación de datos en virtud del cual la
          información recopilada u obtenida no permita la identificación de personas
          determinadas o determinables. • Podrán recopilarse u obtenerse datos
          relacionados al contenido, las comunicaciones u otros datos proporcionados por
          los usuarios al utilizar las plataformas, productos o cualquier otro programa de
          los terceros a los que los desarrollos de Preteco se encuentren conectados
          mediante APIs, siempre y cuando las plataformas, productos o programas cuenten
          con una política de tratamiento de datos. • No podrán tratarse datos obtenidos o
          recopilados mediante APIs a redes sociales u otras fuentes públicas de
          información cuando la fuente de origen sea ilícita o cuya integridad se
          encuentre comprometida. • Queda terminantemente prohibido obtener o recopilar
          datos de fuentes ilícitas o de fuentes en las que obren datos ilícitamente
          obtenidos, sea por medio de hackeos, phishing o cualquier otra forma ilícita de
          obtención de los mismos. • Asimismo, queda prohibido el tratamiento de datos
          sensibles, salvo que el titular de los datos haya consentido expresamente su
          tratamiento y/o el usuario de los datos se encuentre autorizado por ley.
          Integridad en el tratamiento de datos. • Los datos que se obtengan o recopilen
          deberán ser alojados de forma segura y libre de troyanos, gusanos, virus u otro
          tipo de programas maliciosos que vulneren la seguridad de los sistemas y redes
          de Preteco. • Los datos que se obtengan o recopilen, y el tratamiento que se
          haga sobre los mismos, debe quedar asentado en el papel de trabajo del proyecto,
          indicando como mínimo: 1) Plataforma, producto o programa fuente de los datos
          obtenidos, 2) Procedimientos de disociación implementados – de corresponder -,
          3) Tipo de dato/s obtenido/s, 4) Descripción de/los procedimiento/s de
          tratamiento de datos aplicado, 5) Finalidad del tratamiento, 6) Miembros que
          intervienen en el proyecto, indicando roles y responsabilidades. • Los datos que
          se obtengan deben ser utilizados para fines lícitos y que guarden relación con
          la finalidad que origina su obtención o recolección. • No podrán cederse ni
          compartirse, a título gratuito u oneroso, datos que no cuenten con la
          autorización expresa del titular de los mismos. • Tampoco podrán cederse ni de
          cualquier otra forma compartirse, a titulo gratuito u oneroso, los datos
          obtenidos o recopilados mediante APIs a redes sociales o de otras fuentes
          públicas de información. • El tratamiento de datos no podrá tener como finalidad
          promover conductas perjudiciales, ni contribuir a campañas de spam u otras
          experiencias negativas. Tampoco puede tener como finalidad la promoción de
          actividades ilegales, engañosas, fraudulentas o desleales, ni actividades con
          fines no autorizados • En el caso de error o falla en la clasificación de la
          información que se recopile u obtenga de la plataforma, producto o programa a la
          que el desarrollo se encuentre vinculada mediante una API, deberán destruirse
          todos los datos que constituyan datos personales, sensibles y/o confidenciales.
          • Las disposiciones relativas al almacenamiento, respaldo y back up establecidas
          en la POLITICA DE USO DE DISPOSITIVOS MOVILES PSDM.05.22 serán de aplicación al
          proceso de tratamiento de datos. • Los incidentes de seguridad que se sucedan
          durante el tratamiento de datos deberán ser reportados a las áreas de Facility y
          Compliance, a sus efectos, a los fines de restablecer la seguridad de la
          información y sistemas de Preteco, dejando trazabilidad del incidente. La
          presente política constituye los lineamientos mínimos que deben respetarse
          durante todo el proceso de tratamiento de datos, desde su recolección u
          obtención hasta su destrucción definitiva – de corresponder -. Asimismo, todos
          los miembros de Preteco deberán respetar y hacer respetar la normativa aplicable
          y las políticas de tratamiento de datos y/o seguridad de la información de
          terceros, en los casos en que según el tipo de información, mecanismo, base o
          archivo de datos u otro motivo resultaren de aplicación. Los presentes
          lineamientos deberán revisarse cuando, con motivo del desarrollo de sistemas,
          aplicaciones, (COMPLETEN) realizados desde Preteco, las situaciones fácticas
          requieran que deba realizarse tratamiento de datos personales y/o sensibles,
          debiendo Preteco notificar dicho cambio a los terceros con los que se encuentre
          vinculado mediante APIs y adecuarse dicho intercambio a las políticas de
          tratamiento de datos personales y/o sensibles respectivas.
        </p>
        <p>
          IV. INCUMPLIMIENTO DE LA POLITICA Los miembros de PRETECO y/o sus empresas
          relacionadas que incumplan esta Política quedarán sujetos a la aplicación de
          medidas disciplinarias conforme lo establecido en el Código de Conducta y la
          normativa aplicable. A su vez, PRETECO no tomará represalias (sanciones u otras
          medidas disciplinarias) contra un miembro que denuncie de buena fe cualquier
          sospecha o hecho que constituya un incumplimiento a nuestro código, políticas o
          lo establecido por la ley.
        </p>
        <p>
          V. LINEA ETICA Todas las denuncias de presuntos incumplimientos a la política
          serán tratadas de forma confidencial, y pueden realizarse de forma anónima,
          siendo las mismas analizadas por el Comité de Ética. Se puede ingresar la
          denuncia a través de la sección Compliance de nuestro sitio institucional:
          http://preteco.com/ o a través de https://preteco.ethicsway.com/
        </p>
        <p>
          VI. VIGENCIA La presente política entrara en vigor a partir de su publicación y
          conservará su vigencia hasta tanto otra norma interna no la modifique.
        </p>
      </p>
    </div>
  );
};

export default index;
