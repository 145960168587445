export const GET_TAGS = "GET_TAGS";
export const GET_TAGS_SUCCESS = "GET_TAGS_SUCCESS";
export const GET_TAGS_FAILED = "GET_TAGS_FAILED";

export const CREATE_TAG = "CREATE_TAG";
export const CREATE_TAG_SUCCESS = "CREATE_TAG_SUCCESS";
export const CREATE_TAG_FAILED = "CREATE_TAG_FAILED";

export const EDIT_TAG = "EDIT_TAG";
export const EDIT_TAG_SUCCESS = "EDIT_TAG_SUCCESS";
export const EDIT_TAG_FAILED = "EDIT_TAG_FAILED";

export const DELETE_TAG = "DELETE_TAG";
export const DELETE_TAG_SUCCESS = "DELETE_TAG_SUCCESS";
export const DELETE_TAG_FAILED = "DELETE_TAG_FAILED";

export const SELECTED_TAG = "SELECTED_TAG";
export const CLEAN_SELECTED_TAG = "CLEAN_SELECTED_TAG";


export const ASOCIATE_TAG_CLIENT = "ASOCIATE_TAG_CLIENT"
export const ASOCIATE_TAG_CLIENT_SUCCESS = "ASOCIATE_TAG_CLIENT_SUCCESS"
export const ASOCIATE_TAG_CLIENT_FAILED = "ASOCIATE_TAG_CLIENT_FAILED"