import {
  RouteDeleteTags,
  RouteEditTags,
  RouteGetTags,
  RoutePostAsociateTagClient,
  RoutePostTags
} from "constants/TagConstant";
import { deleteHttp, getHttp, postHttp, updateHttp } from "utils/apiHelper";

const tagService = {};

tagService.getTags = function () {
  return getHttp(RouteGetTags);
};

tagService.postTags = function (data) {
  return postHttp(RoutePostTags, data);
};

tagService.updateTags = function (data) {
  return updateHttp(RouteEditTags + "/" + data.id, data);
};

tagService.deleteTags = function (data) {
  return deleteHttp(RouteDeleteTags + "/" + data.id);
};

tagService.asociateTagClient = function (data) {
  return postHttp(RoutePostAsociateTagClient, data);
};

export default tagService;
