import { message } from "antd";
import { all, fork, put, takeEvery } from "redux-saga/effects";
import { getClients } from "redux/actions/Chat";
import {
  asociateTagClientFailed, asociateTagClientSuccess, createTagFailed,
  createTagSuccess,
  deleteTagFailed,
  deleteTagSuccess,
  editTagFailed,
  editTagSuccess, getTags, getTagsFailed,
  getTagsSuccess
} from "redux/actions/Tag";
import {
  ASOCIATE_TAG_CLIENT,
  CREATE_TAG,
  DELETE_TAG,
  EDIT_TAG,
  GET_TAGS
} from "redux/constants/Tag";
import tagService from "services/TagService";

export function* getTagss() {
  //console.log("en saga getclients");
  yield takeEvery(GET_TAGS, function* () {
    try {
      const response = yield tagService.getTags();
      // console.log(clients);
      if (response.status > 400) {
        throw response;
      }
      // message.success(response.data.mensaje);
      yield put(getTagsSuccess(response.data.data));
    } catch (err) {
      message.error(JSON.stringify(err.message));
      yield put(getTagsFailed(err));
    }
  });
}
export function* createTag() {
  yield takeEvery(CREATE_TAG, function* (payload) {
    try {
      const response = yield tagService.postTags(payload.payload);
      if (response.status > 400) {
        throw response;
      }
      message.success(response.data.mensaje);
      yield put(createTagSuccess({}));
    } catch (err) {
      message.error(JSON.stringify(err.message));
      yield put(createTagFailed(err));
    }
  });
}
export function* updateTag() {
  yield takeEvery(EDIT_TAG, function* (payload) {
    try {
      const response = yield tagService.updateTags(payload.payload);
      if (response.status > 400) {
        throw response;
      }
      message.success(response.data.mensaje);
      yield put(editTagSuccess({}));
      yield put(getTags());
    } catch (err) {
      message.error(JSON.stringify(err.message));
      yield put(editTagFailed(err));
    }
  });
}
export function* deleteTag() {
  yield takeEvery(DELETE_TAG, function* (payload) {
    try {
      const response = yield tagService.deleteTags(payload.payload);
      if (response.status > 400) {
        throw response;
      }
      message.success(response.data.mensaje);
      yield put(deleteTagSuccess({}));
      yield put(getTags());
    } catch (err) {
      message.error(JSON.stringify(err.message));
      yield put(deleteTagFailed(err));
    }
  });
}
export function* asociateTagClient() {
  yield takeEvery(ASOCIATE_TAG_CLIENT, function* (payload) {
    try {
      const response = yield tagService.asociateTagClient(payload.payload);
      if (response.status > 400) {
        throw response;
      }
      // message.success(response.data.mensaje);
      yield put(asociateTagClientSuccess({}));
      yield put(getClients());
    } catch (err) {
      message.error(JSON.stringify(err.message));
      yield put(asociateTagClientFailed(err));
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(getTagss),
    fork(createTag),
    fork(deleteTag),
    fork(updateTag),
    fork(asociateTagClient),
  ]);
}
