import {
  CHECK_MESSAGES,
  CHECK_MESSAGES_FAILED,
  CHECK_MESSAGES_SUCCESS,
  CLEAN_DATA,
  EDIT_BOT_FEEDBACK,
  EDIT_BOT_FEEDBACK_FAILED,
  EDIT_BOT_FEEDBACK_SUCCESS,
  FILTER_BY_TAG,
  FILTER_BY_TAG_FAILED,
  FILTER_BY_TAG_SUCCESS,
  GET_CLIENTS,
  GET_CLIENTS_FAILED,
  GET_CLIENTS_SUCCESS,
  GET_MAILS,
  GET_MAILS_FAILED,
  GET_MAILS_SUCCESS,
  GET_MESSAGES,
  GET_MESSAGES_FAILED,
  GET_MESSAGES_SUCCESS,
  RECEIVED_MESSAGE,
  SEND_MESSAGE,
  SEND_MESSAGE_FAILED,
  SEND_MESSAGE_SUCCESS,
  SET_CLIENT_SELECTED,
  SET_CLIENT_SELECTED_INBOX,
  SET_CLIENT_SELECTED_MAIL_INBOX,
} from "../constants/Chat";

const initState = {
  loading: false,
  loadingSend: false,
  loadingClients: false,
  loadingCheck: false,
  loadingFeedback: false,
  messages: [],
  messagesClient: [],
  clientSelected: {},
  clientSelectedInbox: {},
  clientSelectedMailInbox: {},
  dataClients: [],
  showMessage: false,
  tagsForFilter: [],
};

const chat = (state = initState, action) => {
  //console.log({ action });
  switch (action.type) {
    case GET_MESSAGES:
      return {
        ...state,
        loading: true,
      };
    case GET_MESSAGES_FAILED:
      return {
        ...state,
        loading: false,
      };
    case GET_MESSAGES_SUCCESS:
      let result = addMessagesToClient(
        state.dataClients,
        state.clientSelectedInbox,
        action.payload
      );
      return {
        ...state,
        loading: false,
        dataClients: result,
      };

    case GET_MAILS:
      return {
        ...state,
        loading: true,
      };
    case GET_MAILS_FAILED:
      return {
        ...state,
        loading: false,
      };
    case GET_MAILS_SUCCESS:
      let rest = addMessagesToClient(
        state.dataClients,
        state.clientSelectedMailInbox,
        action.payload
      );
      return {
        ...state,
        loading: false,
        dataClients: rest,
      };

    case SEND_MESSAGE: {
      return {
        ...state,
        loadingSend: true,
      };
    }
    case SEND_MESSAGE_SUCCESS: {
      let res = addMessageToDataClientMessages(state.dataClients, action.payload);
      return {
        ...state,
        loadingSend: false,
        dataClients: res,
      };
    }
    case SEND_MESSAGE_FAILED: {
      return {
        ...state,
        loadingSend: false,
      };
    }
    case SET_CLIENT_SELECTED: {
      return {
        ...state,
        clientSelected: action.payload,
      };
    }
    case SET_CLIENT_SELECTED_INBOX: {
      return {
        ...state,
        clientSelectedInbox: action.payload,
      };
    }
    case SET_CLIENT_SELECTED_MAIL_INBOX: {
      return {
        ...state,
        clientSelectedMailInbox: action.payload,
      };
    }
    case RECEIVED_MESSAGE: {
      let result = addMessageToDataClientMessagesAndRefreshCantVistos(
        state.dataClients,
        action.payload
      );
      state.dataClients.clientSelectedInbox &&
        actualizarDataVistosClients(state.dataClients, state.clientSelectedInbox);
      return {
        ...state,
        dataClients: result,
      };
    }
    case GET_CLIENTS:
      return {
        ...state,
        loadingClients: true,
      };
    case GET_CLIENTS_SUCCESS: {
      let res = addPropsCantAndMessages(action.payload); //le paso el dataClient al metodo para agregrar prop de messages and cantidadNovistos.
      return {
        ...state,
        loadingClients: false,
        dataClients: res,
      };
    }
    case GET_CLIENTS_FAILED: {
      return {
        ...state,
        loadingClients: false,
      };
    }
    case FILTER_BY_TAG: {
      return {
        ...state,
        loadingClients: true,
        tagsForFilter: action.payload,
      };
    }
    case FILTER_BY_TAG_SUCCESS: {
      return {
        ...state,
        loadingClients: false,
      };
    }
    case FILTER_BY_TAG_FAILED: {
      return {
        ...state,
        loadingClients: false,
      };
    }
    case CHECK_MESSAGES: {
      return {
        ...state,
        loadingCheck: true,
      };
    }
    case CHECK_MESSAGES_SUCCESS: {
      //let result = actualizarDataVistosClients(state.dataClients, action.payload);
      return {
        ...state,
        loadingCheck: false,
        //dataClients: result,
      };
    }
    case CHECK_MESSAGES_FAILED: {
      return {
        ...state,
        loadingCheck: false,
      };
    }
    case CLEAN_DATA: {
      return {
        ...state,
        loading: false,
        loadingSend: false,
        loadingClients: false,
        loadingCheck: false,
        messages: [],
        messagesClient: [],
        clientSelected: {},
        clientSelectedInbox: {},
        dataClients: [],
        showMessage: false,
      };
    }
    case EDIT_BOT_FEEDBACK:
      return {
        ...state,
        loadingFeedback: true,
      };
    case EDIT_BOT_FEEDBACK_FAILED:
      return {
        ...state,
        loadingFeedback: false,
      };
    case EDIT_BOT_FEEDBACK_SUCCESS:
      let res = actualizarSentimientoMensaje(state.dataClients, action.payload);
      return {
        ...state,
        dataClients: res,
        loadingFeedback: false,
      };
    default:
      return state;
  }
};

const addPropsCantAndMessages = (dataClients) => {
  let res = dataClients.map((item) => {
    return {
      ...item,
      messages: [],
      cantidadNoVistos: item.cantidadNoVistos ? item.cantidadNoVistos : 0,
    };
  });
  return res;
};

const addMessagesToClient = (dataClients, clientSelected, dataMessages) => {
  let res = dataClients.map((item) => {
    return item.ultimoMensaje.id == clientSelected.ultimoMensaje.id
      ? { ...item, messages: dataMessages.messages, cantidadNoVistos: 0 }
      : { ...item };
  });
  return res;
};

const addMessageToDataClientMessages = (dataClients, dataMessage) => {
  let res = dataClients.map((item) => {
    return item.ultimoMensaje.id == dataMessage.idDataMensaje
      ? { ...item, messages: [...item.messages, dataMessage] }
      : { ...item };
  });
  return res;
};

const addMessageToDataClientMessagesAndRefreshCantVistos = (dataClients, dataMessage) => {
  //Añadir msg enviado a array de Data.
  let idSDataMensajes = [];

  let res = dataClients.map((client, index) => {
    idSDataMensajes.push(client.ultimoMensaje.id);
    return client.ultimoMensaje.id == dataMessage.id //relacionamos el id del objeto ultimoMensaje con un idDatamensaje que me viene en el payload de un evento del ws. Vendria a ser como un idConversacion, para identificar a cada conversacion del usuario como diferente.
      ? {
          ...client,
          messages: [
            ...client.messages,
            {
              ...dataMessage.ultimoMensaje,
              fecha: dataMessage.ultimoMensaje.fechaUltimoMensaje,
            },
          ],
          ultimoMensaje: {
            ...dataMessage.ultimoMensaje,
            id: dataMessage.id,
            fecha: dataMessage.ultimoMensaje.fechaUltimoMensaje,
          },
          cantidadNoVistos: dataMessage.cantidadNoVistos,
        }
      : { ...client };
  });
  let exist = idSDataMensajes.indexOf(dataMessage.id);
  if (exist == -1) {
    res = [
      {
        ...dataMessage,
        messages: [dataMessage.ultimoMensaje],
        ultimoMensaje: {
          ...dataMessage.ultimoMensaje,
          id: dataMessage.id,
          fecha: dataMessage.ultimoMensaje.fechaUltimoMensaje,
        },
      },
      ...res,
    ];
  }

  return res;
};

const actualizarDataVistosClients = (dataClients, clientSelected) => {
  let data = [...dataClients];
  data.forEach((element) => {
    if (element.ultimoMensaje.id == clientSelected.ultimoMensaje.id) {
      element.cantidadNoVistos = 0;
    }
  });
  return data;
};

const actualizarSentimientoMensaje = (dataClients, messageNewFeedback) => {
  let data = [...dataClients];
  data.forEach((element) => {
    element.ultimoMensaje.id == messageNewFeedback.idConversacionCliente &&
      element.messages.forEach((item) => {
        if (item.idMensaje === messageNewFeedback.idMensaje) {
          item.sentimiento = messageNewFeedback.sentimiento;
        }
      });
  });
  return data;
};

export default chat;
