import axios from "axios";
import { AUTH_TOKEN } from "redux/constants/Auth";

// axios.interceptors.request.use(function (config) {
//   return config;
// });

async function getHttp(url) {
  let token = localStorage.getItem(AUTH_TOKEN);
  let headers = token && {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  return await axios.get(url, {
    headers,
  });
}

async function postHttp(url, data) {
  let token = localStorage.getItem(AUTH_TOKEN);
  let headers = token && {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  return await axios.post(url, data, {
    headers,
  });
}

async function updateHttp(url, data) {
  let token = localStorage.getItem(AUTH_TOKEN);
  let headers = token && {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  return await axios.put(url, data, {
    headers,
  });
}

async function deleteHttp(url) {
  let token = localStorage.getItem(AUTH_TOKEN);
  let headers = token && {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  return await axios.delete(url, { headers });
}

export { getHttp, deleteHttp, postHttp, updateHttp };
