import { 
  CLEAN_SELECTED_OPERATOR,
  CREATE_OPERATOR, 
  CREATE_OPERATOR_FAILED, 
  CREATE_OPERATOR_SUCCESS, 
  DELETE_OPERATOR, 
  DELETE_OPERATOR_FAILED, 
  DELETE_OPERATOR_SUCCESS, 
  EDIT_OPERATOR, 
  EDIT_OPERATOR_FAILED, 
  EDIT_OPERATOR_SUCCESS, 
  GET_OPERATORS, 
  GET_OPERATORS_FAILED, 
  GET_OPERATORS_SUCCESS, 
  SELECTED_OPERATOR 
} from "redux/constants/Operator"


export const getOperators = (data) => {
  return {
    type: GET_OPERATORS,
    payload: data,
  }
}

export const getOperatorsSuccess = (data) => {
  return {
    type: GET_OPERATORS_SUCCESS,
    payload: data,
  }
}

export const getOperatorsFailed = (msg) => {
  return {
    type: GET_OPERATORS_FAILED,
    payload: msg,
  }
}

export const createOperator = (msg) => {
  return {
    type: CREATE_OPERATOR,
    payload: msg,
  }
}

export const createOperatorSuccess = (msg) => {
  return {
    type: CREATE_OPERATOR_SUCCESS,
    payload: msg,
  }
}

export const createOperatorFailed = (msg) => {
  return {
    type: CREATE_OPERATOR_FAILED,
    payload: msg,
  }
}

export const operatorSelected = (operator) => {
  return {
    type: SELECTED_OPERATOR,
    payload: operator,
  };
};

export const cleanOperatorSelected = () => {
  return {
    type: CLEAN_SELECTED_OPERATOR,
    payload: null,
  };
};

export const editOperator = (data) => {
  return {
    type: EDIT_OPERATOR,
    payload: data,
  };
};

export const editOperatorSuccess = (data) => {
  return {
    type: EDIT_OPERATOR_SUCCESS,
    payload: data,
  };
};

export const editOperatorFailed = (msg) => {
  return {
    type: EDIT_OPERATOR_FAILED,
    payload: msg,
  };
};

export const deleteOperator = (data) => {
  return {
    type: DELETE_OPERATOR,
    payload: data,
  };
};

export const deleteOperatorSuccess = (data) => {
  return {
    type: DELETE_OPERATOR_SUCCESS,
    payload: data,
  };
};
export const deleteOperatorFailed = (msg) => {
  return {
    type: DELETE_OPERATOR_FAILED,
    payload: msg,
  };
};
