export const CLEAN_DATA = 'CLEAN_DATA'; 
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILED = 'SEND_MESSAGE_FAILED';
export const GET_MESSAGES = 'GET_MESSAGES';
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS';
export const GET_MESSAGES_FAILED = 'GET_MESSAGES_FAILED';

export const GET_MAILS = 'GET_MAILS';
export const GET_MAILS_SUCCESS = 'GET_MAILS_SUCCESS';
export const GET_MAILS_FAILED = 'GET_MAILS_FAILED';

export const SET_CLIENT_SELECTED = 'SET_CLIENT_SELECTED';
export const SET_CLIENT_SELECTED_INBOX = 'SET_CLIENT_SELECTED_INBOX';
export const SET_CLIENT_SELECTED_MAIL_INBOX = 'SET_CLIENT_SELECTED_MAIL_INBOX';

export const RECEIVED_MESSAGE = 'RECEIVED_MESSAGE';
export const GET_CLIENTS = 'GET_CLIENTS'; 
export const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS'; 
export const GET_CLIENTS_FAILED = 'GET_CLIENTS_FAILED'; 
export const CHECK_MESSAGES = 'CHECK_MESSAGES'; 
export const CHECK_MESSAGES_SUCCESS = 'CHECK_MESSAGES_SUCCESS'; 
export const CHECK_MESSAGES_FAILED = 'CHECK_MESSAGES_FAILED'; 

export const FILTER_BY_TAG = "FILTER_BY_TAG";
export const FILTER_BY_TAG_SUCCESS = "FILTER_BY_TAG_SUCCESS";
export const FILTER_BY_TAG_FAILED = "FILTER_BY_TAG_FAILED";

export const EDIT_BOT_FEEDBACK = "EDIT_BOT_FEEDBACK";
export const EDIT_BOT_FEEDBACK_SUCCESS = "EDIT_BOT_FEEDBACK_SUCCESS";
export const EDIT_BOT_FEEDBACK_FAILED = "EDIT_BOT_FEEDBACK_FAILED";

