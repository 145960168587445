import { all } from "redux-saga/effects";
import Auth from "./Auth";
import Chats from "./Chats";
import Operator from "./Operator";
import Tag from "./Tag";
import Group from "./Group";
import DataStream from "./DataStream";

export default function* rootSaga(getState) {
  yield all([Auth(), Chats(), Operator(), Tag(),Group(), DataStream()]);
}
