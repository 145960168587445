import {
  SET_BOUNDARIES,
  GET_STATUS,
  GET_STATUS_FAILED,
  GET_STATUS_SUCCESS,
  ADD_RULE_TYPE,
  ADD_RULE_VALUE,
  CREATE_FILTER,
  CREATE_FILTER_FAILED,
  CREATE_FILTER_SUCCESS,
  DELETE_FILTER,
  DELETE_FILTER_FAILED,
  DELETE_FILTER_SUCCESS,
  EDIT_FILTER,
  EDIT_FILTER_FAILED,
  EDIT_FILTER_SUCCESS,
  GET_FILTERS,
  GET_FILTERS_FAILED,
  GET_FILTERS_SUCCESS,
  SELECTED_FILTER,
  CLEAN_SELECTED_FILTER,
} from "redux/constants/DataStream";

const initState = {
  boundaries: {},
  loadingFilter: false,
  filters: [],
  filterSelected: {},
  rules: [],
  loading: false,
};

const dataStream = (state = initState, action) => {
  switch (action.type) {
    case SET_BOUNDARIES:
      return {
        ...state,
        boundaries: action.payload,
      };

    case CREATE_FILTER:
      return {
        ...state,
        loadingFilter: true,
        filter: action.payload,
      };
    case CREATE_FILTER_SUCCESS:
      return { ...state, loadingFilter: false };
    case CREATE_FILTER_FAILED:
      return { ...state, loadingFilter: false };

    case ADD_RULE_VALUE:
      return {
        ...state,
        rules: Object.assign([], state.rules, {
          [action.payload.elementIndex]: {
            ...state.rules[action.payload.elementIndex],
            value: action.payload.value,
          },
        }),
      };
    case ADD_RULE_TYPE:
      return {
        ...state,
        rules: Object.assign([], state.rules, {
          [action.payload.elementIndex]: {
            ...state.rules[action.payload.elementIndex],
            filter: action.payload.filter,
            filterType: action.payload.filterType,
          },
        }),
      };

    case GET_STATUS:
      return {
        loadingFilter: true,
        ...state,
      };
    case GET_STATUS_SUCCESS:
      return {
        loadingFilter: false,
        status: action.payload,
        ...state,
      };
    case GET_STATUS_FAILED:
      return {
        loadingFilter: false,
        ...state,
      };
    case SET_BOUNDARIES:
      return {
        ...state,
        boundaries: action.boundaries,
      };
    case GET_FILTERS:
      return { ...state, loadingFilter: true };
    case GET_FILTERS_SUCCESS:
      return { ...state, loadingFilter: false, filters: action.payload };
    case GET_FILTERS_FAILED:
      return { ...state, loadingFilter: false };

    case SELECTED_FILTER:
      return {
        ...state,
        filterSelected: action.payload,
      };
    case CLEAN_SELECTED_FILTER:
      return {
        ...state,
        filterSelected: {},
      };

    case EDIT_FILTER:
      return { ...state, loading: true };
    case EDIT_FILTER_SUCCESS:
      return { ...state, loading: false };
    case EDIT_FILTER_FAILED:
      return { ...state, loading: false };

    case DELETE_FILTER:
      return { ...state, loading: true };
    case DELETE_FILTER_SUCCESS:
      return { ...state, loading: false };
    case DELETE_FILTER_FAILED:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default dataStream;
