import axios from "axios";
import {
  RouteGetOperators,
  RouteCreateOperator,
  RouteEditOperator,
  RouteDeleteOperator,
} from "constants/OperatorsConstant";
import { AUTH_TOKEN } from "redux/constants/Auth";
import { deleteHttp, getHttp, postHttp, updateHttp } from "utils/apiHelper";

const operatorsService = {};

operatorsService.getOperators = function () {
  return getHttp(RouteGetOperators);
};

operatorsService.createOperator = function (data) {
  return postHttp(RouteCreateOperator, data);
};

operatorsService.updateOperator = function (data) {
  return updateHttp(RouteEditOperator + "/" + data.idOperador, {
    nombre: data.nombre,
    apellido: data.apellido,
    email: data.email,
    tipoDocumento: data.tipoDocumento,
    nroDocumento: data.nroDocumento,
    password: data.password,
    sexo: data.sexo,
    categorias: data.categorias,
  });
};

operatorsService.deleteOperator = function (data) {
  return deleteHttp(RouteDeleteOperator + "/" + data.idOperador);
};

export default operatorsService;
